<template>
	<Page
		ref="page"
		hide-menu
		:background-color="event.pages.Login.backgroundColor"
		poster="https://delivery.eventcdn.net/events/638/1957b356-dff4-4105-acf0-d45286bb5369/4309pandora-background.png"
	>
		<v-container style="min-height: 90vh">
			<v-row style="min-height: 90vh" align="center">
				<v-col cols="12" md="6">
					<div class="d-flex justify-center pb-8">
						<Logo></Logo>
					</div>

					<v-card class="glass" max-width="790px">
						<v-card-text class="px-8">
							<h4 class="text-center white--text">
								JANUARY 24, 2022, 12:00PM EST
							</h4>
							<h2 class="text-center white--text">
								PANDORA STATE OF MIND
							</h2>
							<!-- Error -->
							<template v-if="errors.alreadyRegistered">
								<p class="white--text text-center mt-8">
									You have already registered for this event.
									Please login
									<router-link
										class="
											white--text
											text-decoration-underline
										"
										:to="{ name: 'Login' }"
										>here</router-link
									>
									using the login details, you received on
									email when you registered.
								</p>
							</template>

							<!-- No Error -->
							<template v-else>
								<template v-if="showThanks">
									<p class="mt-8 white--text text-center">
										THANK YOUR FOR REGISTERING, YOU WILL
										RECEIVE AN EMAIL SHORTLY WITH YOUR LOGIN
										DETAILS.
									</p>

									<div class="d-flex justify-center">
										<h2>
											<router-link
												:to="{ name: 'Login' }"
												class="
													text-decoration-underline
													white--text
													text-center
												"
												>CLICK TO LOGIN</router-link
											>
										</h2>
									</div>
								</template>

								<template v-else>
									<p
										class="
											text-center
											white--text
											mb-n2
											pt-8
										"
									>
										REGISTER
									</p>

									<lh-form
										v-model="form.data"
										:schema="form.schema"
									>
									</lh-form>
									<div class="d-flex justify-end pt-8">
										<v-btn
											type="submit"
											:disabled="!isValid"
											large
											class="primary--shadow"
											@click="register"
											>Register</v-btn
										>
									</div>
								</template>
							</template>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</Page>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import STORES from "@/assets/data/stores.js";
export default {
	name: "app-register",

	data() {
		return {
			showPassword: false,
			errors: {
				alreadyRegistered: false,
			},
			showThanks: false,
			user: {
				name: null,
			},

			form: {
				data: {
					name: null,
					email: null,
					store: null,
				},
				schema: {
					global: {
						fields: {
							solo: true,
						},
					},
					groups: [
						{
							fields: [
								{
									name: "name",
									type: "text",
									label: "Full Name",
									placeholder: "Full Name",
									required: true,
								},
							],
						},
						{
							fields: [
								{
									name: "email",
									type: "email",
									label: "Email",
									placeholder: "Email",
									required: true,
								},
							],
						},
						{
							fields: [
								{
									name: "store",
									type: "autocomplete",
									label: "Store Name",
									placeholder: "Find By Store Name",
									required: true,
									items: STORES,
								},
							],
						},
					],
				},
			},
		};
	},

	computed: {
		...mapGetters(["me", "isAuthenticated"]),
		...mapState(["event"]),

		isValid() {
			return Object.values(this.form.data).every((field) => !!field);
		},
	},

	methods: {
		async register() {
			console.log("Data:", this.form.data);

			const data = {
				...this.form.data,
			};

			let response = null;
			try {
				response = await axios.post(
					`${process.env.VUE_APP_API_URL}register/`,
					data
				);
			} catch (exc) {
				if (exc?.response?.status === 400) {
					console.log(
						"Error",
						exc?.response?.data,
						"Status:",
						exc?.response?.status
					);
					this.errors.alreadyRegistered = true;
				}
			}

			if (response && response?.status == 200) {
				console.log(
					"Response status:",
					response?.status,
					"Data:",
					response?.data
				);
				this.showThanks = true;
				this.user.name = `${data.firstname} ${data.lastname}`;

				this.form.data = {};
			} else {
				console.log("Error", response?.data);
				this.errors.alreadyRegistered = true;
			}
		},
	},
};
</script>

<style lang="scss">
</style>
