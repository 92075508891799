export default [
  "N/A",
  "25 Silver Boutique #1",
  "25 Silver Boutique #3",
  "25 Silver Boutique #5",
  "25 Silver Boutique / Wilton Mall",
  "3 Sisters",
  "3224 The Paradies Shops @ Tampa International Airport Corsa",
  "3801 Pandora @ Rehoboth Beach Tanger Outlet",
  "3802 Pandora @ Potomac Mills Outlet",
  "3803 Pandora @ Hilton Head Outlets Store #461",
  "3804 Pandora @ BWI Airport - Store #457",
  "3805 Pandora @ Georgetown - Store #467",
  "3806 Pandora @ Savannah Tanger Outlets",
  "3807 Pandora @ The Walk Outlets",
  "3808 Pandora @ World Trade Center",
  "3809 Pandora @ Sevierville Outlets",
  "3810 Pandora @ Holyoke Mall at Ingleside",
  "3811 Pandora @ Crossgates Mall",
  "3812 Pandora @ Destiny USA",
  "3813 Pandora @ Galleria Crystal Run",
  "3814 Pandora @ Palisades Center",
  "3815 Pandora @ Prudential Center",
  "3816 PANDORA @ New Park Mall - Store #783",
  "3817 Pandora @ Walden Galleria",
  "3818 Pandora @ Garden State Plaza",
  "3819 Pandora @ Christiana Mall",
  "3822 Pandora @ Westmoreland",
  "3823 Pandora @ Marketplace",
  "3824 Pandora @ Broward Mall",
  "3825 Pandora @ Pheasant Lane",
  "3826 Pandora @ Mall of NH",
  "3827 Pandora @ Ross Park Mall",
  "3829 Pandora @ Bridgewater Commons Mall",
  "3830 Pandora @ South Hills Mall",
  "3831 Pandora @ Lincoln",
  "3832 HB Retail / Pandora @ Blakeney Town Center",
  "3833 Pandora @ Asheville Outlets Store # 655",
  "3834 Pandora @ Walnut Street",
  "3835 Pandora @ Outlets of Midsouth",
  "3837 PANDORA @ Pittsburgh Tanger Outlets - Store #773",
  "3838 Pandora @ Branson Outlets",
  "3839 Pandora Fair Oaks",
  "3840 Pandora Annapolis",
  "3841 Pandora Freehold",
  "3842 Pandora Tysons Corner",
  "3843 Pandora Towson #1",
  "3845 Pandora @ Broadway on SoHo",
  "3846 Pandora @ Disney Springs",
  "3847 Pandora @ Williamsburg Outlet",
  "3848 Pandora @ San Marcos Outlet",
  "3849 Pandora @ Woodbury Commons Outlet",
  "3850 Pandora @ National Harbor Outlet",
  "3851 Pandora @ N. Georgia Premium Outlets",
  "3852 Pandora @ Pleasant Prairie Premium Outlets",
  "3853 Memories at Legacy Place dba Pandora",
  "3854 Pandora @ Arden Fair Mall",
  "3856 Pandora @ Broadway Plaza",
  "3857 Pandora @ CambridgeSide Galleria",
  "3858 Pandora @ Cape Cod",
  "3859 Pandora @ Fox Run Mall",
  "3860 Pandora @ Hillsdale Shopping Center",
  "3861 Pandora @ Maine Mall",
  "3862 Pandora @ North Shore Mall",
  "3863 Pandora @ Oakridge Mall",
  "3864 Pandora @ Providence Place Mall",
  "3865 Pandora @ Roseville Galleria",
  "3866 Pandora @ Santa Rosa Plaza",
  "3867 Pandora @ Stoneridge Shopping Center",
  "3868 Pandora @ Stonestown Galleria",
  "3869 Pandora @ Valley Fair Mall",
  "3870 Pandora @ Warwick Mall",
  "3871 Pandora @ Mayfair Mall",
  "3872 Pandora @ The Woodlands Mall",
  "3873 Pandora @ Willowbrook Mall TX",
  "3874 Pandora Fashion Center @ Pentagon City",
  "3875 Pandora @ Valley Plaza",
  "3876 Pandora @ Briarwood Mall",
  "3877 Pandora @ Eastwood Towne Center",
  "3878 Pandora @ Franklin Park Mall",
  "3879 Pandora @ River Town Crossing",
  "3880 Pandora @ Beachwood Place Mall",
  "3881 Pandora @ Belden Village",
  "3883 Pandora @ Lenox Mall",
  "3884 Pandora @ Mall of Georgia",
  "3885 Pandora @ Northpoint Mall",
  "3886 Pandora @ Perimeter Mall",
  "3887 Pandora @ Shops at River Crossing",
  "3888 Pandora @ Town Center at Cobb",
  "3889 Pandora @ Great Lakes Mall",
  "3890 Pandora @ Crabtree Valley",
  "3891 Pandora @ Killeen Mall",
  "3892 Pandora @ Northlake Mall",
  "3893 Pandora @ Shops at Briargate",
  "3894 Pandora @ Short Pump Mall",
  "3895 Pandora @ South Park Mall, NC",
  "3896 Pandora @ Streets at Southpoint",
  "3897 Pandora Fayetteville @ Cross Creek Mall",
  "3898 PANDORA @ Deerbrook Mall - Store #772",
  "3899 PANDORA @ Solano Town Center - Store #791",
  "3901 PANDORA @ Sangertown Square Mall - Store #810",
  "3902 PANDORA @ Algonquin Commons - Store #812",
  "3903 PANDORA @ Geneva Commons - Store #744",
  "3904 PANDORA @ Shops at Saddle Creek - Store #808",
  "3905 PANDORA @ Monroeville - Store #801",
  "3906 PANDORA @ Eastwood Mall - Store #706",
  "3907 PANDORA @ The Centre at Salisbury - Store #809",
  "3908 PANDORA @ The Forum at Peachtree Parkway - Store #814",
  "3909 PANDORA @ Oglethorpe Mall - Store #831",
  "3911 PANDORA @ Poughkeepsie Galleria - Store #806",
  "3912 PANDORA @ Howell Tanger Outlets - Store #818",
  "3913 Pandora @ Natick Mall",
  "3915 Pandora @ Lakeside Mall",
  "3916 Pandora @ Mall of Louisiana",
  "3917 Pandora @ Northstar",
  "3918 Pandora @ San Francisco Centre",
  "3919 PANDORA @ Del Monte Center - Store #860",
  "3920 PANDORA @ Dover Mall - Store #813",
  "3921 PANDORA @ Bay Street - Store #858",
  "3922 PANDORA @ Fox River Mall - Store #722",
  "3923 PANDORA @ Tanger Outlets Mebane - Store #817",
  "3924 PANDORA @ Northridge Mall - Store #793",
  "3925 PANDORA @ Woodland Hills - Store #779",
  "3926 PANDORA @ Shops at Riverside - Store #807",
  "3927 PANDORA @ Cross County Shopping Center - Store #866",
  "3928 PANDORA @ Pearland Town Center - Store #841",
  "3934 Pandora @ Rockaway Town Square",
  "3935 Pandora @ Monmouth Mall",
  "3936 Pandora @ Montgomery Mall",
  "8281 Pandora @ George Bush Intercon  Airport",
  "8535 The Paradies Shops, LLC dba Pandora @ Philadelphia Intl",
  "8704 Pandora @ Charlotte Airport",
  "8750 The Paradies Shops dba Pandora @ Detroit Intl Airport",
  "8770 The Paradies Shops, LLC dba Pandora @ Atlanta Airport",
  "8996 The Paradies Shops @ JFK TERMINAL 4",
  "A & J Jewelers",
  "A Touch Of Class/Jackson",
  "Accents",
  "Ace of Diamonds / Mount Pleasant",
  "Adela's",
  "Agapanthus",
  "Alain Jewelers",
  "Alan Miller Jewelers",
  "Alayna's Boutique",
  "Alayna's Boutique / Leland",
  "Alayna's Boutique / Rocky Mount",
  "Alayna's Boutique / Wilmington",
  "Alberto & Co @ Isabela",
  "Alberto & Co.",
  "Albert's Diamond Jeweleres / E-Commerce",
  "Albert's Diamond Jewelers / Schererville",
  "ALEXANDER ZACHARY JEWELERS",
  "Alicia's Jewelry",
  "All About You / Beaumont",
  "Allan Jewelers",
  "Altemueller Jewelry",
  "American Jewelry",
  "Anderson Jewelers",
  "Anfesa's Jewelers",
  "Anna's / Lakeland 2",
  "Anna's St Armands",
  "Apothecary Gift Shop",
  "Apricot Lane",
  "Arezzo Jewelers",
  "Arlene Fine Jewelry",
  "Armbruster Jewelers",
  "ARS Associates dba Cudos 2 U",
  "Artisan Jewelers/Key West",
  "Artisans @ Venice",
  "Artisans Periwinkle Place / Sanibel",
  "Artsy Abode / AUG",
  "Artsy Abode @ Paddock Mall",
  "Artsy Abode @ The Orange Park Mall",
  "Artware",
  "Atelier Couture",
  "Atlanta West Jewelry",
  "Atlanta West Jewelry/Carrollton",
  "Atlantic Jewelry Exp Co",
  "BALDORIA",
  "Baldoria / Londonderry",
  "Ballerina Jewelers II",
  "Banks Jewelry",
  "Barbara’s Hallmark",
  "Barbara's Hallmark / Erie",
  "Barnes Jewelers / Goldsboro",
  "Barnes Jewelers / Oconomowoc",
  "Barry's Boutique",
  "Bay Jewelers",
  "Bead Enterprises (Curacao) B.V.",
  "Bead Enterprises (USA) LLC DBA Pandora Miami International Mall",
  "Beau Rivage Resort & Casino",
  "Bechdel Jewelers",
  "Bedazzle Adaliz Bonaire",
  "Bejeweled, The Jewelry Store",
  "Bell Jewelers",
  "Bella Gia",
  "Belle Jewelers",
  "Ben Bridge # 203/Pandora @ Alderwood Mall - Info Only Acct",
  "Ben Bridge # 211 / Pandora @ Chandler Fashion Center - Info Only",
  "Ben Bridge # 225/Pandora @ Santa Anita Mall - INFO ONLY ACCT",
  "Ben Bridge # 227/Pandora @ Northridge Fashion - INFO ONLY ACCT",
  "Ben Bridge #101 / River Park Square - INFO ONLY ACCT",
  "Ben Bridge #11 / Capital Mall - INFO ONLY ACCT",
  "Ben Bridge #201/Pandora @ Southcenter Mall - Info Only Acct",
  "Ben Bridge #204/Pandora @ Galleria at Tyler - Info Only Acct",
  "Ben Bridge #207/ Pandora @ Bellevue Square Mall - Info Only",
  "Ben Bridge #212/Pandora @ Del Amo Fashion Center - Info Only",
  "Ben Bridge #213/Pandora @ Plaza Bonita - INFO ONLY AC",
  "Ben Bridge #214/Pandora @ Washington Square - Info Only",
  "Ben Bridge #215/ Pandora @ Pearlridge Mall - Info Only",
  "Ben Bridge #216/Pandora @ Tacoma Mall - INFO ONLY ACCT",
  "Ben Bridge #217/Pandora @ San Tan Village - Info Only Acct",
  "Ben Bridge #218/Pandora @ Scottsdale Fashion Square - Info Only",
  "Ben Bridge #219/Pandora @ Topanga Plaza - Info Only Acct",
  "Ben Bridge #220/Pandora @ Glendale Galleria - Info Only Acct",
  "Ben Bridge #221/Pandora @ Scottsdale Quarter - Info Only Acct",
  "Ben Bridge #222/Pandora @ Victoria Gardens - Info Only",
  "Ben Bridge #223/Pandora @ University Towne Centre - Info Only",
  "Ben Bridge #224/Pandora @ Los Cerritos Center - Info Only",
  "Ben Bridge #226 / Pandora @ Downtown Portland - INFO ONLY ACCT",
  "Ben Bridge #228/ Pandora @ Arrowhead TC - INFO ONLY ACCT",
  "Ben Bridge #229 / Pandora @ Parkway Plaza - INFO ONLY ACCT",
  "Ben Bridge #231/Pandora @ Westfield Culver City - INFO ONLY ACCT",
  "Ben Bridge #233 / Pandora @ Intl Market Place - INFO ONLY ACCT",
  "Ben Bridge #235 / Hollywood & Highlands",
  "Ben Bridge #240 / PANDORA @ Sherman Oaks",
  "Ben Bridge #242 / PANDORA @ Temecula",
  "Ben Bridge #30 / Kitsap Mall - INFO ONLY ACCT",
  "Ben Bridge #39 / Bellis Fair Mall - INFO ONLY ACCT",
  "Ben Bridge #400 / PANDORA @ Desert Hills Premium Outlet",
  "Ben Bridge #60 / Galleria at Sunset - INFO ONLY ACCT",
  "Ben Bridge #64 / La Encantada - INFO ONLY ACCT",
  "Ben Bridge #742 / Pandora @ Downtown Disney - INFO ONLY ACCT",
  "Ben Bridge #791 / E-Commerce INFO ONLY",
  "BEN DAVID JEWELERS",
  "Benqueil's",
  "Benson Diamond Jewelers",
  "Berlin Village Gift Barn",
  "Best Exotic Jewelry",
  "Bijoux",
  "Blair's Jewelry & Gifts",
  "Bliss Jewelers",
  "Bliss Jewelers Key West",
  "Blocher Inc",
  "Blue Diamond",
  "Blue Diamond #3",
  "Blue Diamond / Ketchikan",
  "Blue Diamond Gems",
  "Bolenz Jewelry",
  "Boolchand's / Aruba",
  "Boolchand's / Front Street 1",
  "Boolchand's LTD DBA Pandora Brickell City Centre",
  "Boolchand's LTD DBA Pandora Harbour Point",
  "Boolchand's LTD DBA Pandora Havensight",
  "Boolchand's Ltd. DBA Pandora St. Thomas",
  "Boolchands Pessoomal(Bovenwindse Eilanden)B.V. DBA Pandora Phili",
  "Borden’s Jewelry Company / Cookeville",
  "B'outique @ Rexville Town Center",
  "B'outique @ Santa Juanita",
  "Bradley's Jewelers",
  "Braidy Jewelers",
  "Brasfield Jewelers",
  "Brenny's Jewelry",
  "Bridal and Co.",
  "Brownlee Jewelers Arboretum",
  "Brownlee Jewelers Park Road",
  "Brownlee Jewelers Rockhill",
  "Bungalows / Chartres",
  "Bungalows at The Wharf",
  "Caldwell Jewelers - Fajardo Jewelry",
  "Calvin Broyles Jewelers / Beckley",
  "Calvin Broyles Jewelers / Charleston",
  "Calvin Broyles Jewelers / Hurricane",
  "Caribbean Gems",
  "Carolina Girls/ Charleston #1",
  "Carrollton House",
  "Carter's Diamond Jewelers",
  "Cary R&D Enterprises, Inc. DBA Lynn's Hallmark - Wilson",
  "Casa D'oro Diamonds & Fine Jewelry",
  "Casey's Hallmark",
  "Cave Sheppard 29113",
  "CCSD DUTY FREE, SRL",
  "Charmed Jewels",
  "Chastain Jewelers",
  "Chateau Christine",
  "CHMS Ltd. dba Just Jai's",
  "Choices & More",
  "Christopher Robert Gifts",
  "Christopher William Jewelers / Harrisonburg",
  "Cisneros Fine Jewelry",
  "Clark Jewelers/Elizabethtown",
  "Classic Boutique & Gallery",
  "Classic Diamond & Jewelry / San Jose",
  "Classic Diamond & Jewelry @ Westfield Wheaton",
  "Classy Touch",
  "Collector's Gallery / Sausalito",
  "Colombian Emeralds International",
  "Colombian Emeralds International / Christ Church",
  "Colony 13 @ Northgate Mall",
  "Colors",
  "Confer's Jewelers",
  "Connie & V. Cross Jewelers",
  "Connors Mercantile",
  "Corinth Jewelers",
  "Cosmopar",
  "Couch's Jewelers/Anniston",
  "Crescent Jewelers / Frankfort",
  "Crescent Jewelers / Lansing",
  "Crescent Jewelers / Lithonia",
  "CRIDER JEWELERS/ SANDUSKY",
  "Crimson Ridge",
  "Crisda Collections",
  "Crisson Jewelers",
  "Crisson Jewelers / Dockyard",
  "Crown Diamond Jewelers @ Gulfview Square Mall",
  "Crystal Fish Gifts of Distinction",
  "Crystal Jewelers",
  "Cumberland Bead Boys",
  "Cynthia's Choice",
  "Dan Gould Jewelers",
  "Danson Jewelers",
  "Dara Beauty",
  "David Arlen Jewelers",
  "David's Jewelers/Florham Park",
  "Dazzler's/Diamante",
  "Debbie's Jewels",
  "Deliz y Joyeros",
  "Delores Jewelers / Alafaya Trail",
  "Delores Jewelers / East Colonial Drive",
  "Dempsey's Jewelers",
  "Details for the Home",
  "DeWayne's",
  "Diamond & Co / Decatur",
  "Diamond & Dials @ Greeley Square",
  "Diamond Concepts / Chesterfield",
  "Diamond Concepts / Manassas",
  "Diamond District 901",
  "Diamond Exchange",
  "Diamond Gallery / Lenoir City",
  "Diamond Gallery / Morristown",
  "Diamond Gallery @ Macomb Mall / Roseville",
  "Diamond Jewelers/Gulf Shores",
  "Diamond Ring Co",
  "Diamond Showcase",
  "Diamonds & Co Orlando Airport",
  "Diamonds & Co.",
  "Diamonds 4Ever @ North Riverside Mall",
  "Diamonds By Dianne",
  "Diamonik @ Plaza Guayama",
  "Dimo Fine Jewelry",
  "Dior / Lawrenceville",
  "Dior Jewelers",
  "Dior Jewelers / Greenbriar",
  "Dior's Jewelers/Decatur",
  "Dior's Jewelers/Lithonia",
  "Disney @ La Mascarade - INFO ONLY ACCT",
  "Disney @ Legends of Hollywood",
  "Disney @ Uptown Jeweler - INFO ONLY ACCT",
  "Divine Jewelers",
  "DK Boutique",
  "D'ore Jewelry",
  "Dragonflies",
  "Dreamstones",
  "Dreamstones @ C3",
  "DUFRY PUERTO PLATA CORPORATION - AMBER COVE",
  "Dufry Puerto Rico Terminal A",
  "Dufry Puerto Rico Terminal BC",
  "Duncan Fine Jewelry",
  "Dunkins Diamonds / New Philadelphia",
  "Dunkin's Diamonds @ Cleveland Ave",
  "Dunkin's Diamonds @ Indian Mound",
  "Dunkin's Town Center",
  "E Boutike @ Popular Center Building",
  "E.G. LANDIS JEWELERS",
  "Eckerman Jewelry",
  "Eden Jewelry / Eden",
  "Eden Jewelry / Martinsville",
  "Eden Jewelry / South Boston",
  "Effie & Isabell's @ Rensselaer County Plaza",
  "Either Ore Jewelers",
  "Elegant Jewelers II",
  "Elias Diamonds",
  "Elisa Ilana Designs",
  "Ellie & Isabelle",
  "Ellie Mae's Boutique",
  "Enchanted",
  "Enchantress Co.",
  "Estilo Boutique & Gifts / The Vineyard",
  "Exotic Gems",
  "Exotic Gems / Bradenton",
  "Exquisite Jewelers",
  "Exton Place",
  "F.L. Crooks & Co.",
  "Fabriola's",
  "Fan Fare Gifts and Collectible",
  "Fanedos II",
  "Fanedos Jewelry",
  "Farley's Jewelers",
  "Farm-Way Inc.",
  "Feldstein Jewelers",
  "Feldstein Jewelers / Green Bay",
  "Feulah",
  "Fiona's",
  "FIRST CAPITOL TRADING POST",
  "Five Fish Inc.",
  "Fond Memories",
  "Fox Fine Jewelry",
  "Frank Jewelers",
  "Freeport Jewelers / Zuikertain Mall",
  "Freeport Jewelers/ Renaissance Mall",
  "Friedman's Jewelers",
  "G.L. & Markella",
  "Gaines Jewelry -2",
  "GALA JEWELERS",
  "Gallery of The Lakes",
  "Garieri Jewelers",
  "Gary Blanchard Jewelers",
  "Gem Shop & Diamonds",
  "Gemorie",
  "Gemorie / Chino Hills",
  "General Store of Minnetonka",
  "Gift Box",
  "Gifted by the Sea",
  "Gifted/Robbinsville",
  "Giftology / Westfield",
  "Giorgio Conti Jewelers",
  "GLATZ JEWELERS",
  "Glow",
  "Gold House",
  "Gold Valley Skj Inc. @ Eastdale Mall",
  "Golden Classics Jewelers",
  "Golden Gifts Jewelry",
  "Golden Gypsies Gifts",
  "GOODMAN & SONS/ Hampton",
  "Gracious Me",
  "Grand Jewelers",
  "Grand Jewelers/Grand Blanc",
  "Gray Fox / Leesburg",
  "Gray Fox of Lakeland",
  "Gray Fox/Clermont",
  "Grayson Jewelers",
  "Greeks Bearing Gifts",
  "Griner Jewelry Co.",
  "Grogan Jewelers / Florence",
  "Gumbo Limbo Coastal Chic",
  "Hallmark at Olive Park",
  "Halpy Family Jewelers DBA Hannoush Jewelers @ Poughkeepsie Galle",
  "Hang Ups",
  "Hannah's Home Accents",
  "Hannoush # 05 / Burlington",
  "Hannoush # 217",
  "Hannoush #06",
  "Hannoush #108",
  "Hannoush #110",
  "Hannoush #127",
  "Hannoush #146",
  "Hannoush #204 @ Crystal Mall",
  "HANNOUSH #41",
  "Hannoush #54",
  "Hannoush #55",
  "Hannoush #56",
  "Hannoush #707 - Brockton",
  "Hannoush #726 @ Emerald Square Mall",
  "Hannoush #729",
  "Hannoush #757",
  "Hannoush Jewelers #25",
  "Hannoush Jewelers / Newburgh",
  "Hannoush Jewelers/Dartmouth",
  "Hannoush Jewelers/Winston Salem",
  "Hannoush Leominster",
  "Harry Edwards / Castries",
  "Harry Edwards / Grenada",
  "Harry Edwards Jeweler",
  "Harry Edwards Jewelers 3",
  "Harstans Jewelers / Guilford",
  "Hartville Collectibles",
  "Heart and Home/Newark",
  "Heart and Home/Wilmington",
  "Heart of The South",
  "Heart Strings",
  "Hedges",
  "Henry's/Cape May",
  "Henry's/Ocean City",
  "Her Hide Out",
  "Herman's Creations East",
  "High Cotton Boutique",
  "Holland Jewelry",
  "Holley Jewelers",
  "Hoppe Jewelers",
  "Hurwitz",
  "I.M. Jewelers",
  "In Style NYC",
  "Indo Caribbean Enterprises (Aruba) N.V.",
  "Infinity Jewelry",
  "Insight @ Jacksonville Airport / Post-Security",
  "Insight @ Jacksonville Airport / Pre-Security",
  "Insight @ River City Marketplace",
  "International Concepts",
  "Inthai / Brownsville",
  "Inthai / Mission",
  "Intrigue Jewelers",
  "Island Breeze",
  "Island Jewellers / Camana Bay",
  "Italics Royale",
  "Ivy Ridge Traditions",
  "J FOSTER JEWELERS/MAUMEE",
  "J&B Family Jewelers",
  "J&JB Associates, Inc. / Sierra Lily",
  "J. Howard Jewelers",
  "J. WM Brasfield Jewelers",
  "Jacob Matthew Jewelers",
  "James' Jewel Box",
  "Jan's Hallmark",
  "Jared's E-Commerce #2001 / Info Only Acct",
  "Jayson Jewelers Too",
  "Jefferson Estate Jewelers",
  "Jenna's Just in Boutique",
  "Jewel Gallery",
  "Jewel House",
  "Jewelers Avenue",
  "Jeweler's Bench/Elizabethton",
  "Jewelers Outlet",
  "Jewellers International",
  "Jewelry Emporium",
  "Jewels By the Sea",
  "Jewelzz",
  "Jim Kryshak Jewelers",
  "JM Princewell",
  "JMR Jewelers",
  "Jo-An’s Boutique",
  "John Bull / Harbour Bay",
  "John Bull / Mall @ Marathon",
  "John Bull / South West Plaza",
  "John Bull LTD",
  "John S Cryan Jewelers",
  "Johnson Jewelers / Shops on Mesa",
  "Joseph & Sons Jewelers",
  "Joyeria Frances @ Mercado Plaza #19",
  "Joyeria La Turquesa",
  "Joyeria Marrero / Cidra",
  "Joyeria Marrero 2 / Aibonito",
  "Joyeria Venecia #1/Bayamon",
  "Juneau Jewelry Co.",
  "June's Gift Boutique",
  "Just Between Friends",
  "Justice Jewelers",
  "JWR JEWELERS",
  "K . Chandiram Limited / Bijoux Jewelers 5",
  "K . Chandiram Limited/Bijoux Jewelers 1",
  "K. Chandiram Limited / Bijoux 7",
  "K. Chandiram Limited/ Bijoux Jewelers 3",
  "K. Chandiram Limited/Bijoux Jewelers 6",
  "Kanricks Jewelers",
  "Kardesh Jewelers",
  "Karen's Hallmark",
  "Karla's",
  "Katy's / Nashville",
  "Kay Cameron",
  "Kazazian's Fine Jewelry",
  "Keepsakes Jewelry / White Hall",
  "Kelley Jewelers",
  "Kent McKenzie Jewelers",
  "Kettermans Jewelers",
  "Kiefer  Jewels  #1/Dade City",
  "Kiefer Jewels # 2/Lutz",
  "Kimberly Jewelry / Akron",
  "Kim's Hallmark",
  "Kirk Freeport",
  "Kobe Jewelry",
  "Koerber's Fine Jewelry",
  "Koras Jewelers",
  "Kristen's Coin & Jewelry",
  "Kury/Plaza Las Americas",
  "Kux Jewelers",
  "L.S. Wholesale, Inc./Bridgetown",
  "L.S. Wholesale, Inc./Tortola",
  "La Penha Duty Free",
  "La Vie Boutique",
  "Lantz's Pharmacy & Gifts",
  "Laurie's Hallmark / Belle Vernon",
  "Lawrence Jewelers #2",
  "Lebanon Jewelry & Gift",
  "Lefler's Fashions",
  "Leitzel's Jewelry",
  "Leitzel's Jewelry - Hershey",
  "Leo's Jewelry & Gifts",
  "Leslie's Jewelers",
  "Levy Jewelers #2/Downtown",
  "Lexington Jewelers",
  "Lily Mae's/Wake Forest",
  "Lincroft Village Jewelers",
  "Lin's Jewelry Co.",
  "Little Green Apple / Avon",
  "Little Green Apple / Lafayette",
  "Little Treasury Jewelers",
  "Livani",
  "Long Jewelers",
  "Lotte Duty Free Guam, LLC",
  "Luby @ Mayaguez Mall",
  "Lucy's / Mansell",
  "Lucy's / McGinnis",
  "Lucy's / Oglethorpe",
  "Lucy's / Prado",
  "Lucy's / Riverstone",
  "Lucy's Gift Boutique / Fleming Island",
  "Lucy's Gift Boutique / Harbour Place",
  "Lucy's Gift Boutique / Julington Village",
  "Lucy's Gift Boutique / Lakewood Promenade",
  "Lucy's Gift Boutique / Oakleaf Town Center",
  "Lucy's Gift Boutique / Ormond Beach",
  "Lucy's Gift Boutique / Riverside",
  "Lucy's Gift Boutique / Roosevelt",
  "Lucy's Gift Boutique / Shoppes @ Bartram Park",
  "Lucy's Gift Boutique @ Dunlawton Square",
  "LuElla's Gift Market",
  "Lynn's Hallmark / Apex",
  "M & M Jewelers #2",
  "M MAZZONI JEWELERS",
  "Maharajas Fine Jewelry #1",
  "Maloof Jewelry & Gifts",
  "Malsons @ Kings Plaza",
  "Marcie-N-Me",
  "Maren's",
  "Margaret's of Burlington",
  "Mari Lou's Fine Jewelry",
  "Maria's Fine Jewelers",
  "Marines #4100 MCX Cherry Point",
  "Marines Henderson Hall Main Exchange #01100",
  "Marines Miramar Main Exchange #11100",
  "Marines Quantico Main Exchange #02100",
  "Martin Binder Inc",
  "Maryville Jewelers",
  "Maui Ocean Center",
  "Maureen's Boutique",
  "Maurices Jewelers II, INC",
  "Maurice's Jewelers/Miami",
  "May Jewelers",
  "May Jewelers / Sumter",
  "McKenzie & Smiley Jewelers / Madison St.",
  "McKenzie and Smiley Jewelers / Wilma Blvd",
  "McKenzie's Hallmark Shoppe Paintsville",
  "Medawar Jewelers / Flint",
  "Medawar Jewelers / Lansing",
  "Medawar Jewelers/Fenton",
  "Medawar Jewelers/Jackson",
  "Medawar Jewelers/Okemos",
  "Medical Park Boutique",
  "Mees Jewelry",
  "Meierotto's",
  "Melody's Quality Jewelry/ Mexico",
  "Meyer Jewelers / Green Bay",
  "Meyers Jewelers / Columbus",
  "Mia Of London",
  "Miami Lakes Jewelers",
  "Michaels Jewelers / Danbury",
  "Michaels Jewelers / Farmington",
  "Michaels Jewelers /Bristol",
  "Michaels Jewelers /Manchester",
  "Michaels Jewelers /Meriden",
  "Michaels Jewelers /Waterbury",
  "Michaels Jewelers/Orange",
  "Michel's Inc DBA MJ Diamonds / Sterling Heights",
  "Michelson Jewelers",
  "Milkins Jewelers",
  "Millie's Hallmark",
  "Min D's",
  "Mirage Fine Jewelers",
  "Miss Dotties",
  "Miss Dotties / Kathleen",
  "Mitchum Jewelers",
  "MJ Diamonds",
  "MJ Diamonds / Oakland Mall",
  "MJ Diamonds @ Fairlane Town Centry / Dearborn",
  "MJ Diamonds @ Great Lakes Crossing",
  "Monica Jewelers",
  "MONMOUTH JEWELERS",
  "Mountz Jewelers / Carlisle",
  "Mountz Jewelers / Harrisburg",
  "Mozart's",
  "Mykonos",
  "MyNavyExchange.com",
  "Na Pua Jewelers 2",
  "Naser Jewelers/Plaistow",
  "Nasr Jewelers / Denton",
  "Nature Coast Goldsmith",
  "Navy Exchange / Bethesda",
  "Navy Exchange / Charleston - Goose Creek",
  "Navy Exchange / Guam - Store #994",
  "Navy Exchange / Jacksonville",
  "Navy Exchange / Little Creek",
  "Navy Exchange / Memphis",
  "Navy Exchange / Norfolk",
  "Navy Exchange / Oceana",
  "Navy Exchange / Orlando",
  "Navy Exchange / Patuxent River",
  "Navy Exchange / Pearl Harbor",
  "Navy Exchange / Pensacola",
  "Navy Exchange / San Diego",
  "Navy Exchange / Yokosuka",
  "Navy Exchange/Whidbey",
  "Necker's Jewelers/Davenport",
  "Necker's Jewelers/DeWitt",
  "Nejma's Boutique",
  "Nest Feathers",
  "Neves Jewelers / Shrewsbury",
  "Neves Jewelers / Woodbridge",
  "Nick T Arnold Jewelers",
  "Nick T. Arnold #2",
  "Nini's Treasures",
  "NM Collections",
  "Noble Jewelers",
  "Nordstrom.com",
  "Nordstrom/Alderwood - Store 10",
  "Nordstrom/Annapolis Mall - Store 626",
  "Nordstrom/Bellevue - Store 4",
  "Nordstrom/Brea Mall - Store 321",
  "Nordstrom/Cherry Hill - Store 637",
  "Nordstrom/Fashion Island - Store 333",
  "Nordstrom/Fashion Valley - Store 360",
  "Nordstrom/International Plaza Mall - Store 765",
  "Nordstrom/Irvine Spectrum - Store 330",
  "Nordstrom/Los Cerritos - Store 322",
  "Nordstrom/Michigan Avenue - Store 220",
  "Nordstrom/Mission Viejo - Store 326",
  "Nordstrom/NYC Flagship - Store 210",
  "Nordstrom/Oak Brook - Store 221",
  "Nordstrom/Old Orchard Mall - Store 223",
  "Nordstrom/Park Meadows - Store 34",
  "Nordstrom/Roosevelt Field - Store 524",
  "Nordstrom/Scottsdale Mall - Store 380",
  "Nordstrom/Shops at Merrick Park - Store 763",
  "Nordstrom/South Coast Plaza - Store 320",
  "Nordstrom/Southcenter - Store 5",
  "Nordstrom/Stonebriar Mall - Store 724",
  "Nordstrom/Tacoma Mall - Store 6",
  "Nordstrom/The Westchester - Store 523",
  "Nordstrom/Troy - Store 228",
  "Nordstrom/Twelve Oaks - Store 235",
  "Nordstrom/Tysons Corner - Store 600",
  "Nordstrom/UTC - Store 361",
  "Nordstrom/Valley Fair - Store 425",
  "Nordstrom/Woodfield Mall - Store 225",
  "Nordstroms/Ala Moana - Store 706",
  "Norman's #52",
  "Norman's Gift Shop",
  "Occasionally Yours / Liberty",
  "Occasionally Yours / Polaris",
  "Occasionally Yours / The Greene",
  "Occasionally Yours/Columbus",
  "Occasionally Yours/Fairfield",
  "Occasionally Yours/Hamilton",
  "Olde Wicker Mill",
  "Olivia Ryan",
  "Omni Skagway",
  "Omni/Juneau",
  "Osborne Jewelers",
  "Oscar's Watch & Jewelry",
  "Outside and In",
  "Oz's Jewelers",
  "P.R. Sturgill",
  "Palomino Jewelry",
  "Pandora / The Gallery at Harborplace",
  "Pandora @ 12 Oaks Mall",
  "Pandora @ 42nd Street",
  "Pandora @ Acadiana Mall / Reeds #173",
  "Pandora @ Agora Mall",
  "Pandora @ Akron",
  "Pandora @ Ala Moana Center",
  "Pandora @ Altamonte Springs",
  "PANDORA @ American Dream",
  "PANDORA @ Arbor Place",
  "PANDORA @ Atlantic Station",
  "Pandora @ Atlantic Terminal",
  "Pandora @ Aventura",
  "PANDORA @ Barton Creek",
  "Pandora @ Bay Ridge",
  "Pandora @ Bay Street",
  "Pandora @ Baybrook Mall",
  "PANDORA @ Bayshore Town Center",
  "PANDORA @ Blue Mall Punta Cana",
  "PANDORA @ Blue Mall Santo Domingo - Store #984",
  "Pandora @ Boca Town Center",
  "Pandora @ Brea Mall",
  "PANDORA @ Bridgetown Barbados",
  "Pandora @ Burlington Mall",
  "Pandora @ Carolina Place / Reeds #161",
  "Pandora @ Castleton Square",
  "Pandora @ Charleston Place",
  "Pandora @ Cherry Creek",
  "Pandora @ Cherry Hill Mall",
  "PANDORA @ Cherryvale Mall",
  "Pandora @ Chicago Ridge",
  "Pandora @ Cielo Vista Mall",
  "Pandora @ Citrus Park Mall",
  "Pandora @ Coastal Grand Mall",
  "Pandora @ Coconut Point Town Center",
  "Pandora @ Columbia Mall",
  "Pandora @ Columbiana Centre / Reeds 157",
  "PANDORA @ Cool Springs",
  "Pandora @ Coral Square",
  "Pandora @ Coronado Mall",
  "Pandora @ Countryside Mall",
  "Pandora @ Crestview Hills",
  "PANDORA @ Crocker Park - Store #932",
  "Pandora @ Crossroads",
  "PANDORA @ Cumberland Mall",
  "Pandora @ Dadeland Mall",
  "Pandora @ Danbury Fair Mall",
  "PANDORA @ Dania Pointe",
  "Pandora @ Deer Park Town Center",
  "Pandora @ Deptford Mall",
  "Pandora @ Destin Commons",
  "Pandora @ Downtown Center",
  "Pandora @ Dulles Town Center",
  "PANDORA @ EastChase Town Center",
  "Pandora @ Eastland Mall",
  "Pandora @ Easton Town Center",
  "PANDORA @ Edison Mall",
  "Pandora @ Fashion District Outlets - Store #973",
  "Pandora @ Fashion Fair Mall",
  "Pandora @ Fashion Mall at Keystone",
  "PANDORA @ Fashion Outlets of Chicago",
  "Pandora @ Fashion Show Mall",
  "PANDORA @ Fashion Valley",
  "Pandora @ Fayette Mall",
  "Pandora @ First Colony Mall",
  "Pandora @ Flat Iron Crossing",
  "Pandora @ Florida Mall",
  "PANDORA @ Forest Hills",
  "Pandora @ Forum Shops",
  "PANDORA @ Fox Valley #2",
  "Pandora @ Galeria 360 SDQ",
  "Pandora @ Galleria Ft Lauderdale",
  "Pandora @ Glenbrook Square",
  "Pandora @ Governors Square",
  "Pandora @ Grand Canal Shoppes Venetian",
  "PANDORA @ Green Acres Mall",
  "PANDORA @ Green Hills Mall",
  "Pandora @ Greenwood Park",
  "PANDORA @ Hamilton Place",
  "Pandora @ Hanes Mall",
  "PANDORA @ Harlem Irving Plaza",
  "Pandora @ Hawthorn Mall",
  "Pandora @ Haywood Mall",
  "Pandora @ Herald Square",
  "PANDORA @ Hillside Village - Store #836",
  "Pandora @ Houston Galleria",
  "PANDORA @ Hudson Yards - Store #901",
  "PANDORA @ Independence Center",
  "Pandora @ Island Plaza",
  "PANDORA @ Jackson Heights",
  "Pandora @ Jordan Creek Mall",
  "Pandora @ Kenwood Mall",
  "Pandora @ King Of Prussia Mall",
  "PANDORA @ Kings Plaza",
  "Pandora @ La Cantera",
  "Pandora @ La Palmera Mall",
  "Pandora @ La Plaza",
  "Pandora @ LaCenterra at Cinco Ranch - Store #917",
  "Pandora @ Las Catalinas",
  "PANDORA @ Las Vegas Outlets South",
  "Pandora @ Lehigh Valley Mall",
  "Pandora @ Liberty Township",
  "PANDORA @ London Square",
  "Pandora @ Louis Joliet Mall",
  "PANDORA @ Lynnhaven Mall",
  "Pandora @ MacArthur Mall",
  "Pandora @ Macy's Galleria Dallas",
  "Pandora @ Macy's Galleria Houston",
  "Pandora @ Macy's Lenox Square",
  "Pandora @ Macy's Memorial City",
  "Pandora @ Macy's Roosevelt Field",
  "Pandora @ Magnificent Mile",
  "PANDORA @ Mall at Bay Plaza",
  "Pandora @ Mall at Rockingham Park",
  "Pandora @ Mall at Short Hills",
  "Pandora @ Mall at Wellington Green",
  "Pandora @ Mall Del Norte / Reeds # 174",
  "Pandora @ Mall of America",
  "Pandora @ Mall of St Matthews",
  "PANDORA @ Mandalay Place",
  "PANDORA @ Mayaguez Mall",
  "Pandora @ McCarran Airport",
  "PANDORA @ McCarran Airport, C-Gate",
  "Pandora @ McCarren Airport, Terminal 3",
  "PANDORA @ Meadowood Mall",
  "PANDORA @ Melbourne Square / Reeds #178",
  "Pandora @ Memorial City",
  "Pandora @ Menlo Park Mall",
  "PANDORA @ MGM Grand Las Vegas",
  "Pandora @ Midland Park",
  "Pandora @ Millenia Mall",
  "PANDORA @ Mills at Jersey Gardens - Store #976",
  "Pandora @ Miracle Mile",
  "Pandora @ Mt. Pleasant Town Center",
  "Pandora @ Naperville",
  "Pandora @ Newport Centre",
  "Pandora @ North East Mall",
  "Pandora @ North Park Center",
  "Pandora @ North Riverside",
  "PANDORA @ Oak Park Mall",
  "Pandora @ Oakbrook",
  "Pandora @ Ocean County Mall",
  "Pandora @ Old Orchard",
  "Pandora @ Old San Juan",
  "Pandora @ Oranjestad",
  "Pandora @ Orland Square",
  "Pandora @ Palm Beach Gardens",
  "Pandora @ Paramus Park Mall",
  "Pandora @ Park City Center",
  "Pandora @ Park Meadows",
  "Pandora @ Park Place Mall",
  "Pandora @ Park Plaza Mall",
  "Pandora @ Partridge Creek",
  "Pandora @ Patrick Henry Mall",
  "Pandora @ Pembroke Gardens",
  "Pandora @ Pembroke Lakes Mall",
  "Pandora @ Penn Square Mall",
  "PANDORA @ Pinnacle Hills Promenade",
  "Pandora @ Plaza Carolina",
  "Pandora @ Plaza del Caribe",
  "Pandora @ Plaza Del Norte",
  "Pandora @ Plaza Del Sol",
  "PANDORA @ Plaza International",
  "Pandora @ Plaza Las Americas, San Juan",
  "Pandora @ Polaris Fashion Place",
  "Pandora @ Port Lucaya Marketplace",
  "PANDORA @ Promenade at Bolingbrook",
  "Pandora @ Quakerbridge Mall",
  "PANDORA @ Queens Center",
  "PANDORA @ Renaissance at Colony Park - Store #780",
  "Pandora @ Ridge Hill",
  "Pandora @ Rio Grande Valley Premium Outlets - Store #991",
  "Pandora @ Riverchase Galleria",
  "Pandora @ Roosevelt Field Mall",
  "Pandora @ Rosedale Center",
  "Pandora @ Sagemore at Promenade",
  "Pandora @ Saint Louis Galleria",
  "PANDORA @ Sambil Curacao",
  "PANDORA @ San Patricio Plaza",
  "Pandora @ Santa Monica",
  "PANDORA @ Shoppes at Bel Air",
  "PANDORA @ Shoppes at Bridge Street",
  "Pandora @ Shops at Chestnut Hill",
  "PANDORA @ Shops at Clearfork",
  "PANDORA @ Shops at Friendly Center",
  "Pandora @ Shops at Summerlin",
  "Pandora @ Shops Saucon Valley",
  "PANDORA @ Smith Haven Mall",
  "Pandora @ Somerset Collection",
  "PANDORA @ SoNo Collection",
  "PANDORA @ South Coast Plaza",
  "Pandora @ South Park Mall, OH",
  "PANDORA @ South Plains Mall",
  "Pandora @ South Shore Mall",
  "PANDORA @ Southlake Town Square",
  "PANDORA @ Southland Center",
  "PANDORA @ Southland Mall",
  "Pandora @ Spotsylvania Town Center",
  "Pandora @ Springfield Mall",
  "PANDORA @ St. Charles Towne Center",
  "PANDORA @ St. Clair Square",
  "Pandora @ St. John Town Center / Jacksonville",
  "Pandora @ Staten Island Mall",
  "Pandora @ Stony Point",
  "PANDORA @ Sunrise Mall - Reeds #179",
  "PANDORA @ Tanger Riverhead II Outlets - Store #972",
  "PANDORA @ The Avenue at Murfreesboro - Store #908",
  "PANDORA @ The Avenues Mall",
  "PANDORA @ The District at Green Valley Ranch",
  "Pandora @ The Greene",
  "Pandora @ The Mercato",
  "Pandora @ The Oaks Mall / Gainesville",
  "Pandora @ The Parks at Arlington",
  "Pandora @ The Westchester",
  "Pandora @ Town East Mall",
  "Pandora @ Treasure Coast Square",
  "Pandora @ Trumbull",
  "Pandora @ Tucson Mall",
  "Pandora @ Tuttle Mall",
  "Pandora @ Tyrone Square",
  "PANDORA @ University Park",
  "Pandora @ University Town Center",
  "PANDORA @ Valencia Town Center",
  "Pandora @ Valley Mall / Reeds #170",
  "Pandora @ Valley View Mall",
  "PANDORA @ Viewmont Mall",
  "PANDORA @ Village of Merrick Park",
  "PANDORA @ Village of Rochester Hills",
  "Pandora @ Vintage Faire",
  "Pandora @ Volusia Mall",
  "Pandora @ Walt Whitman Mall",
  "Pandora @ Water Tower",
  "Pandora @ Watters Creek",
  "PANDORA @ West County Center",
  "Pandora @ West Farms mall",
  "PANDORA @ West Town Mall",
  "PANDORA @ West Towne Mall",
  "Pandora @ Westfield Brandon",
  "Pandora @ Westfield South Shore Mall, Bay Shore",
  "Pandora @ Westland Mall",
  "Pandora @ Willow Grove Mall",
  "Pandora @ Willowbrook Mall NJ",
  "Pandora @ Wiregrass",
  "PANDORA @ Wolfchase Galleria",
  "Pandora @ Woodbridge Center Mall",
  "Pandora @ Woodfield Mall",
  "PANDORA @ Woodland Mall",
  "PANDORA @ Wrentham Premium Outlets- Store #979",
  "PANDORA Capital City Mall",
  "Pandora Dallas Galleria",
  "PANDORA International Plaza and Bay Street",
  "Pandora Las Vegas Outlet",
  "Pandora Mall of San Juan",
  "Pandora Oxford Valley Mall",
  "Pandora Pensacola @ Cordova Mall",
  "Pandora Stonebriar",
  "Pandora@The Falls",
  "Park Place Jewelers #2",
  "Park Place Jewelers / Ocean City",
  "Pattern",
  "Patti's 1880's Settlement LLC",
  "Paul Antypas Jeweler",
  "Pear Tree Avenue / Brentwood",
  "Penelope / Forest",
  "Penelope/Charlottesville",
  "Penelope/Chesterfield",
  "Penelope/Daleville",
  "Penelope/Farmville",
  "Penelope/Glen Allen",
  "Penelope/Lynchburg",
  "Penelope/Midlothian",
  "Penelope/Richmond",
  "Perrywinkles Fine Jewelry#1/Burlington",
  "Perrywinkles Fine Jewelry/Plattsburg",
  "Peter Franklin Jewelers/Angola",
  "Peter Franklin Jewelers/Ft. Wayne",
  "Peter Franklin Jewelers/New Haven",
  "Pfeifley Jewelers",
  "PFSWEB",
  "PFSWEB_POS",
  "Pink Flamingo",
  "Pochy Joyeros",
  "Polka Dotz / Orlando",
  "Polka Dotz Chic",
  "Polka Dotz/Kissimmee",
  "Pollack Jewelers Inc @ Sawgrass Mill",
  "Pollocks Fine Jewelers",
  "Poolside",
  "Portofino Jewelers",
  "Posh 41",
  "Precious Accents",
  "Precision Jewelers",
  "Premier Product Resourcing, Inc.",
  "Primrose Galleries",
  "Princess World Jewelers",
  "Queens Jewelers",
  "R. A. Georgetti & Co",
  "Rare Essentials",
  "Ray's Jewelry",
  "Rays Jewelry #2",
  "Red Barn Company Store LLC",
  "Reeds # 17/ Lakewood",
  "Reeds # 2 / Niagara Falls",
  "Reeds # 3 / Maple",
  "Reeds #14 / DeWitt",
  "Reeds #25 / Asheville Mall",
  "Reeds #43",
  "Reeds #5 E-Commerce",
  "Reeds #76 @ Cross Creek Mall",
  "Reed's Jewelers #105 / Francis Scott Key Mall / INFO ACCOUNT",
  "Reeds Jewelers #107 / Patrick Henry Mall",
  "Reeds Jewelers #108 / Northlake Mall",
  "Reeds Jewelers #113 / Concord Mills",
  "Reeds Jewelers #114 / Valley Mall",
  "Reed's Jewelers #117 / White Marsh Mall",
  "Reeds Jewelers #122 / Mayfaire Town Center",
  "Reeds Jewelers #125 / Wolfchase Galleria",
  "Reeds Jewelers #126 / Bonita Lakes Mall",
  "Reeds Jewelers #137 / Arundel Mills",
  "Reed's Jewelers #138 / York Galleria",
  "Reeds Jewelers #142 / Sunrise Mall",
  "Reeds Jewelers #148 / Streets at Southpoint",
  "Reeds Jewelers #149 / Triangle Town Center",
  "Reeds Jewelers #154 / Pier Park",
  "Reeds Jewelers #168 / Potomac Mills",
  "Reeds Jewelers #176",
  "Reeds Jewelers #20 / Greenbrier Mall",
  "Reed's Jewelers #23 / Turtle Creek Mall",
  "Reeds Jewelers #24 / Mall at Barnes Crossing",
  "Reeds Jewelers #29 / Old Hickory Mall",
  "Reed's Jewelers #32 / University Mall",
  "Reed's Jewelers #34 / Northpark Mall",
  "Reeds Jewelers #35 / Edgewater Mall",
  "Reeds Jewelers #44 / Peachtree Mall",
  "Reeds Jewelers #46 / South Park Mall",
  "Reeds Jewelers #48 / Chespeake Square",
  "Reeds Jewelers #58 / Independence Mall",
  "Reed's Jewelers #59 / Magnolia Mall",
  "Reeds Jewelers #60 / The Village at Sandhills",
  "Reeds Jewelers #62 / Charleston Town Center",
  "Reeds Jewelers #67 / Jacksonville Mall",
  "Reeds Jewelers #69 / Westfield Shoppingtown at Eastridge",
  "Reeds Jewelers #72 / Greenville Mall",
  "Reeds Jewelers #75 / Glynn Place Mall",
  "Reed's Jewelers #79 / Northwoods Mall",
  "Reeds Jewelers #83 / Santa Rosa Mall",
  "Reeds Jewelers #98 / Albany Mall",
  "Reed's Jewelers / Mobile",
  "Reeds Jewelers/Orchard Park",
  "Regal Jewelers/Victoria",
  "Reichman Jewelers",
  "Renee’s of South Padre Island",
  "Rick Murphey the Jeweler",
  "Riddles Jewelry # 118 / W Burlington",
  "Riddle's Jewelry #106 / Rapid City",
  "Riddle's Jewelry #114 / Grand Forks",
  "Riddle's Jewelry #116 / Ft. Dodge",
  "Riddle's Jewelry #117 / Manhattan",
  "Riddles Jewelry #119 / Fargo",
  "Riddle's Jewelry #120 / Cheyenne",
  "Riddle's Jewelry #124 / Sioux Falls",
  "Riddle's Jewelry #125 / Watertown",
  "Riddle's Jewelry #127 / Casper",
  "Riddle's Jewelry #129 / Minot",
  "Riddle's Jewelry #130 / Sioux City",
  "Riddle's Jewelry #150 / North Platte",
  "Riddle's Jewelry #152 / Waterloo",
  "Riddle's Jewelry #153 / Ames",
  "Riddle's Jewelry #154 / Davenport",
  "Riddle's Jewelry #155 / Salina",
  "Riddle's Jewelry #157 / Gillette",
  "Riddle's Jewelry #159 / Grand Island",
  "Riddle's Jewelry #173 / Mitchell",
  "Riddle's Jewelry #182 / St. Cloud",
  "Riddle's Jewelry #192 / Coralville",
  "Riddle's Jewelry #193/ Rochester",
  "Riddle's Jewelry #194 / Sioux Falls Empire Mall",
  "Riddle's Jewelry #196 /  Mankato",
  "Riddle's Jewelry #33 / Mason City",
  "Riddle's Jewelry #331 / Bismarck",
  "Riddle's Jewelry #332 / Great Falls",
  "Riddle's Jewelry #336 / Dickinson",
  "Riddle's Jewelry #337 / Billings",
  "Riddle's Jewelry #341 / Bozeman",
  "Riddle's Jewelry #342 / Kalispell",
  "Riddle's Jewelry #72 / Garden City",
  "Riddle's Jewelry @ Gateway Mall #190 / Lincoln",
  "Riddles Jewelry Kearney / #151",
  "Rihners Jewelers",
  "Robertson Jewelers",
  "Rochelle Jewelers",
  "Rochell's Diamonds",
  "Rochell's Diamonds / Plaza Fiesta",
  "Rogers #11/Fresno",
  "Rogers Jewelers #14/Elkgrove",
  "Rojo's Collection / Doramar",
  "Rojos Collection @ Premium Outlets Barceloneta",
  "Rolfs Jewelers",
  "Rome Jewelers / Wilmington",
  "Romm Diamonds",
  "Rosenfeld Jewelry",
  "Roth Jewelers",
  "Royal Jewels / Arch Plaza",
  "Royale Jewelers",
  "Ruby's",
  "Rumanoff's",
  "Rummele's Jewelers/Manitowoc",
  "S.V.S. Fine Jewelry",
  "SAAD's Fine Jewelry",
  "Sachs Jewelers",
  "Saginaw Gold & Diamond Center",
  "Salem Creek",
  "San Francisco Gold",
  "Saxon's / Bel Air",
  "Saxon's/Aberdeen",
  "Scarlet Pearl Resort & Casino",
  "Second Street Fashions",
  "Selman's",
  "Serendipity / Naples",
  "Serendipity Gifts & Toys",
  "Seybridge Pharmacy Jewelry & Gifts",
  "Sharon Boutique / Honolulu",
  "Shimmering Seas Jewelry/Panama City",
  "Shirin Diamond Center",
  "Shop195",
  "Signature/Golden Nugget @ Atlantic City",
  "Silver Corner",
  "Silverland",
  "Simply Charmed / Hays",
  "Sky by LGM",
  "Smyth Jewelers Timonium",
  "So Mermazing",
  "Solitaire Diamond @ Opry Mills",
  "Something Special / Midland",
  "Southern Charm Boutique",
  "Spath Jewelers / Bartow",
  "Stadheim Jewelers/Albert Lea",
  "Stadheim Jewelers/Mason City",
  "Sterling Jewelers / Wethersfield",
  "Sterlings",
  "Stiles Jewelers",
  "Studio",
  "Styles & Trend",
  "Susan's Hallmark / Birmingham",
  "Susan's Hallmark / Columbus",
  "Susan's Hallmark / Starkville",
  "Symphony Jewelers",
  "Talner",
  "Tena's Fine Diamonds / Athens",
  "Tena's Jewelry & Gift Shop / Washington",
  "Tena's Jewelry & Gifts / Hartwell",
  "Tena's Jewelry / Elberton",
  "The Biltmore Company",
  "The Boutique at Merle Norman",
  "The Bronze Lady",
  "The Copenhagen House",
  "The Cotton Gin",
  "The Cotton Gin / Corolla",
  "The Cotton Gin / Duck",
  "The Cotton Gin / Nags Head",
  "The Diamond Center/Janesville",
  "The Diamond Center/Madison",
  "The Forest Collection",
  "The Gem Collection",
  "The Gift Box / Havertown",
  "The Jewelry Boutique",
  "The Jewelry Box / Intercourse",
  "The Jewelry Center",
  "The Jewelry Center/ Brookfield",
  "The Jewelry Chest",
  "The Jewelry Connection",
  "The Jewelry Studio",
  "The Mermaids Tale",
  "The Mole Hole @ Myrtle Beach",
  "The Mole Hole- North Myrtle Beach",
  "The Mustard Seed",
  "The Pink Pearl",
  "The Pink Pearl dba Lynn's Hallmark",
  "The Potpourri House",
  "The Red Tulip @ Greensboro Shopping Center",
  "The Select Shop",
  "The Shoppes at American Candle",
  "The Silver Box",
  "The Source / Eastview",
  "The Source / Monroe Avenue",
  "The Source / W. Ridge Road",
  "The Thoughtful Spot",
  "The Trendy Trunk",
  "The Village Papery",
  "The Wildflowers",
  "The Willow Gift Gallery",
  "Threads",
  "Three Bears General Store",
  "Three's Company Gifts",
  "Throggs Neck Jewelers",
  "Time After Time / Bensalem",
  "Time After Time / Exton",
  "Time After Time / North Wales",
  "Time After Time / Ocean",
  "Time After Time / Tropicana",
  "Time After Time @ Plymouth Meeting Mall",
  "Time After Time/Cherry Hill",
  "Time After Time/Moorestown",
  "Tin Cottage",
  "Tin Cottage / Columbia",
  "Tipton's Fine Jewelry",
  "Tivoli Jewelers",
  "TJMaxx",
  "Tom Poe",
  "Touch Of Class Jewelers / East Dickson",
  "Town Square Jewelers",
  "Towne Square Jewelers",
  "Treasure Box",
  "Treasure Box/Statesboro",
  "Treasure Jewelers / Palatka",
  "Treasures @ Jefferson Mall",
  "Treasure's Jewelers / Madisonville",
  "Treasure's Jewelers/Maryville",
  "Treasures The Diamond Place",
  "Trudy's / Lake Jackson",
  "Trudy's / Shenandoah",
  "Trudy's Hallmark / Federal Way",
  "Trudy's/Copperfield",
  "True Love Boutique",
  "Tuck's of Saugatuck",
  "Tuck's of Traverse City",
  "Tulalip Resort & Casino",
  "Turners, Inc. @ Applewood Center Place",
  "Uptown Exclusives",
  "Vanities",
  "Vaughan's",
  "Velvet Pumpkin",
  "VEN - 3900 Merchandising Booth Events",
  "VEN - Rue La La",
  "Vernon Powell Shoes",
  "Versant",
  "Vince Jewelers / Hagatna",
  "Vince Jewelers / Tamuning",
  "Vince Jewelers / Tumon",
  "Vinhas Jewelers",
  "Walk on Water/Lake Mary",
  "Waterfall Jewelers 3",
  "Waterfall Jewelers II @ Village Lakes",
  "Watson Jewelers",
  "Weekends Fashions",
  "Wendels",
  "Wexford Jewelers & Gallery",
  "White House Boutique",
  "White's Fine Jewelry / Fairmont",
  "White's Jewelers/Springfield",
  "William S. Nacol Jewelry",
  "Windsor Jewelers DBA Pandora Jewelry",
  "WINDSOR JEWELERS/AUGUSTA",
  "With Heart & Soul",
  "Wits End Giftique, Inc.",
  "WM James Leather Co.",
  "Wolf Fine Jewelers / Pier Shop @ Caesars",
  "Wolf Fine Jewelers @ Monmouth Mall",
  "WOODARDS",
  "XS Diamonds @ Fairlane Town Center",
  "Yanko Jewelers",
  "Yelverton Jewelers",
  "Yesterday's @ Arnot Mall",
  "Youngblood's",
  "Your Jewelry Box / Altoona",
  "You've Been Framed",
  "Z Fine Jewelry @ Gurnee Mills Mall",
  "Zappos",
  "Zehnder's of Frankenmuth",
  "",
  "1483338 Alberta Ltd.- Pandora-West Edmonton Mall",
  "1485629 Alberta Ltd O/A Pandora Jewelry Banff",
  "1604088 Ontario Inc. - Pandora Markville",
  "1604088 Ontario Inc.- Promenade Pandora",
  "2297097 Ontario Inc. - Square One Pandora",
  "2322571 Ontario Inc.O/APandora Mapleview",
  "35887 Yukon Inc",
  "3808 Pandora Park Place",
  "3809 Pandora Bower Place",
  "3810 Pandora Orchard Park",
  "3811 Pandora Cornwall Centre",
  "3812 Pandora Eaton Centre",
  "3813 Pandora Fairview Mall",
  "3814 Pandora Chinook Centre",
  "3815 Pandora SouthGate",
  "3816 Pandora Rideau",
  "3817 Pandora Mic Mac",
  "3818 PANDORA @ The Core",
  "3819 Pandora @ Bayshore Mall",
  "3820 Pandora @ Limeridge Mall",
  "9047-1467 Quebec Inc-Joaillerie Jean Langevin",
  "9275-9356 Quebec Inc-Pandora Capitale",
  "9281-3500 Quebec Inc.-Pandora de L'Estrie",
  "Ann Louise Jewellers - Bay Centre",
  "Ann Louise Jewellers - Cross Iron Mills",
  "Ann Louise Jewellers - Kamloops",
  "Ann Louise Jewellers Ltd. -  Metrotown",
  "Ann-Louise Jewellers Ltd. - Hillside Centre",
  "Appelt Jewellery (G.P.) Ltd. - Ness",
  "Appelt's Jewellery (K.P) Ltd. - Regent",
  "Appelt's Jewellery Ltd. - Morden",
  "Armand Jewellers",
  "Au Bout Du Monde",
  "Barrington Place Jewellers",
  "Berdrock Investments Inc-Oshawa Pandora",
  "Big Apple Imports Ltd. - Appelt Kenaston",
  "Bijouterie Dube",
  "Bijouterie La Perle Rare Inc.",
  "Bijouterie La Perle Rare Inc.#2",
  "Bijouterie Lingot D'Or",
  "Bijouterie Mozart - Repentigny",
  "Bijouterie Mozart Rimouski -9333886 Canada Inc",
  "Bijouterie Prestige",
  "Bijouterie R. Savard",
  "BLUE ICE JEWELLERS",
  "Bogart's Jewellers - Mount Pearl",
  "Bogart's Jewellers-St. John's",
  "Bogart's Jewellery Ltd.- Grand Falls",
  "Bogart's Jewelry Ltd - Cornerbrook",
  "Bogart's Jewelry Ltd. - Gander",
  "BOGARTS-AVALON MALL",
  "Bogarts-Carbonear",
  "Bradshaws",
  "Brancier Jewellers",
  "Brilliant Gold Jewellery",
  "Christine Jewellers Ltd.- Aberdeen",
  "COOK'S JEWELLERS - Prince Rupert",
  "Cook's Jewellers - Terrace BC",
  "Cortesis Jewelers",
  "Diamond Dreams Jewellers Inc.",
  "Direct Diamonds & Gold - DDG Jewellery Inc.",
  "Distributeur de l'Est",
  "Elgin Jewellers",
  "Emerald Isle",
  "Fiancee Jewellers",
  "Fifth Avenue Jewellers",
  "Finch Centre Jewellers",
  "G&P Goldsmiths Ltd.O/A Spicer Jewellery",
  "Gem Designs Jewellery Inc.",
  "Gerald Perreault (1988) Inc.",
  "GNM Fine Jewellers Ltd",
  "Goldstream Jewellers",
  "Graziella Fine Jewellery - Oshawa",
  "Graziella Fine Jewellery - Whitby",
  "Guo Jewellery",
  "Hamawi Jewellers",
  "HAMMOND JEWELLERS",
  "Harmony House Jewellery - Grimsby",
  "Hooper's Jewellers",
  "Infinity Family Jewellers 1814191 Ontario Inc.",
  "Janina’s Diamonds and Time",
  "JC Bradley Jewellers - Vernon",
  "JH Young & Sons Ltd.",
  "Jinny's China & Gifts - Etobicoke",
  "Joaillerie Langevin-Delson 9047-1467 Quebec Inc.",
  "Joaillerie Monaco St. Jerome",
  "Joaillerie Monaco-LaSalle",
  "Johnson Jewellers",
  "Kavar Jewellers Inc. - Bramalea City Centre",
  "Kavar Jewellers Inc. / Pen Centre",
  "La Bijouterie Nappert - Metivier Inc.",
  "Lauener Bros. Jewellers Ltd.",
  "Legacy Jewellers 1970548 ONT LTD",
  "Les Bijoutiers Doucet 1993 Inc.",
  "Manhattan Home",
  "Mani Jewellers",
  "Mary's Fine Jewellery",
  "MICHAELS JEWELLERS",
  "Modern Eye Gallery And Gift LTD.",
  "Most Bijoux Inc.",
  "Mozart St Georges - 9333886 Canada Inc.",
  "Norton's Jeweller",
  "PANDORA @ Bramalea City Centre",
  "Pandora @ Cambridge Centre",
  "Pandora @ Carrefour Laval",
  "Pandora @ Conestoga Mall",
  "Pandora @ Fairview Pointe-Claire",
  "Pandora @ Galeries d'Anjou",
  "PANDORA @ Halifax Shopping Centre",
  "PANDORA @ Hillcrest Mall",
  "PANDORA @ Kingsway Mall",
  "PANDORA @ Les Promenades Gatineau",
  "PANDORA @ Market Mall",
  "PANDORA @ Montreal Eaton Centre- Store #996",
  "Pandora @ Peel Street",
  "Pandora @ Place du Royaume",
  "PANDORA @ Polo Park",
  "Pandora @ Quartier DIX30",
  "Pandora @ Rockland Centre",
  "PANDORA @ St. Vital Centre",
  "PANDORA @ Yorkdale Mall",
  "PANDORA @Southcentre",
  "Pandora Cataraqui Centre - Arno Investments Inc.",
  "Pandora Coquitlam-9512608 Canada Inc.",
  "Pandora Dufferin Mall- SRR Dufferin Holdings Inc.",
  "Pandora Erin Mills - 2382968 Ontario Inc.",
  "Pandora FairviewParkMall-Overgaard Gemologists-WaterlooLtd",
  "Pandora Georgian Mall-2321074 Ontario Inc",
  "Pandora Guelph-2391756 Ontario Inc",
  "Pandora Guildford - 9512608 Canada Inc.",
  "Pandora Masonville",
  "Pandora Mayfair -9512608 Canada Inc.",
  "Pandora Metrotown - Ben Bridge-Jeweler Inc.",
  "Pandora Oakville - 1941450 Ontario Inc.",
  "Pandora Pacific Centre - Ben Bridge Jeweler Inc.",
  "Pandora Park Royal - Ben Bridge Jeweler Inc.",
  "Pandora Peterborough - Cortesis Jewelers Peterborough Inc.",
  "Pandora Pickering Town Centre",
  "Pandora Richmond - Ben Bridge - Jeweler, Inc.",
  "Pandora Scarborough Town Centre",
  "Pandora Sevenoaks - 9512608 Canada Inc.",
  "Pandora Sherway",
  "Pandora St.Laurent - 2492087 Ontario Inc.",
  "Pandora St-Bruno",
  "Pandora Ste Foy",
  "Pandora Sudbury - 2396679 Ontario Inc.",
  "Pandora Upper Canada Mall",
  "Pandora Vaughan - 1604088 Ontario Inc.",
  "Pandora Whistler - 9512608 Canada Inc.",
  "Pandora White Oaks - Jinny'sChina&GiftsLtd.",
  "Pandora Willowbrook - Ben Bridge - Jeweler, Inc.",
  "Pandora Windsor - 2446834 Ontario Inc.",
  "Pandora-Shops at Don Mills",
  "Peace Bridge Duty Free",
  "Pear Home",
  "PFSWeb Canada Ecommerce",
  "Phoenix Jewellers",
  "Pizazz Gifts Ltd.",
  "Polar Ice",
  "Pommier Jewellery",
  "PRCL - The Outlet Collection at Niagara",
  "PRCL-Pandora Cookstown Tanger Outlet",
  "Premium Marketing Group Inc.",
  "Presents - Niagara Falls",
  "Provenance Fine Things Ltd.",
  "Rabethge's Jewellery Ltd.",
  "Rochells Jewellers - Central City",
  "Rodan Jewellers",
  "RUTLEDGE JEWELLERS",
  "Sophie Cosmetics & Accessories Ltd",
  "Spicer Cole Fine Jewellers",
  "Spicer Cole Fine Jewellery Ltd - Pandora Champlain Place",
  "Stylessence Fine Jewellery O/A  1478873 Ont. Inc",
  "T.H Burns Jewellers LTD",
  "Taing Jewellers",
  "Taylors Jewellers-John.W.Taylor Jewellers(1995) Ltd",
  "Towns Jewellers",
  "WDFG VANCOUVER L.P.",
];
